<template>
  <v-container fill-height fluid style="background-color: #e9ecef">
    <v-row>
      <v-col cols="12" class="d-flex flex-column justify-center align-center">
        <v-img
          max-height="226"
          max-width="456"
          src="/ws.png"
        ></v-img>
      </v-col>
      <v-col
        cols="12"
        xs="12"
        sm="12"
        md="12"
        lg="12"
        xl="12"
        class="d-flex flex-column justify-center align-center"
      >
        <router-view></router-view>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "OutsideContextApplication",

  computed: {
    currentBreakpoint: function () {
      return this.$vuetify.breakpoint.name;
    },

    resizeComponentsByBreakPoint: function () {
      const breakpointUpdate = ["xs", "sm"];
      const breakpointCurrent = this.currentBreakpoint;

      return breakpointUpdate.includes(breakpointCurrent);
    },
  },
};
</script>

<style scoped>
.bg-primary {
  background-color: #0b6775;
}
</style>